body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/TORG_Sidebar_v5_bottom.png');
  background-image: url('/CH_Header.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: repeat-y;
}

h3 {
	color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
	color: white;
	font-weight: bold;
}
div.roorButton {
	margin-top: 10px;
	margin-bottom: 10px;
}

div.Round {
	padding: 20px;
	margin-bottom: 10px;
	margin-left: 10px;
	margin-right: 10px;
	border-style: solid;
	border-width: thin;
	border-color: black;
	border-radius: 30px;
	background-color: #4682b4aa;
	font-weight: 600;
	color: white;
}

div.Reconnected {
	background-color: #008000aa;
}

div.Transformed {
	background-color: #cf0202aa;
}

input[type='number']::-webkit-inner-spin-button, 
input[type='number']::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0;
}

div.formrow {
	margin-top: 10px;
	margin-bottom: 40px;
}

div.condrow {
	padding-top: 20px;
	padding-bottom: 20px;
}

div#conditions {
	background-color: #aaaaaa66;
	border-radius: 10px;
	margin-bottom: 10px;
	margin-left: 10px;
	margin-right: 10px;
}

div.custom-control-right {
    padding-right: 24px;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
}
div.custom-control-right .custom-control-label::after{
    right: -1.5rem;
    left: auto;
}
div.custom-control-right .custom-control-label::before {
    right: -2.35rem;
    left: auto;
}

div.elapsedTime, div.dnResults {
	font-size: small;
	color: #cccccc;
}

button.destinyButton {
	color: white;
}

button, span {
	vertical-align: middle;
}

button.btn:active {
    color: #343a40;
}
button.btn:hover {
    color: gray;
}
